<template>

    <div class="w-full h-full px-3 py-4 relative">

        <div class="h-full w-full flex flex-col">

            <div class="h-full flex flex-col relative rounded-lg shadow-card p-2 mx-1 overflow-hidden" :class="'bg-module-'+mode">

                <!-- <div class="h-12 w-full flex flex-row">

                    <div class="h-full w-full py-1 px-2 flex flex-row justify-between items-center">

                        <span class="text-xl font-semibold" :class="'text-dfont-'+mode">{{$t('labdays')}}</span>

                    </div>

                </div> -->

                <div class="h-16 flex flex-col justify-center items-center">

                    <!-- <el-select v-model="yearselected" centered :placeholder="$t('select')" class="w-10/12 mx-auto shadow-card rounded-lg" :class="'bg-box-'+mode">
                        <el-option
                            v-for="item in years"
                            :key="item"
                            :label="item"
                            :value="item"
                            class="w-full"/>
                    </el-select>                     -->
                    <select name="yearselected" v-model="yearselected" class="h-full w-full px-2" :class="'bg-box-'+mode+' text-dfont-'+mode">
                        <option v-for="(el,index) in years" :key="index" :value="el" :class="'text-dfont-'+mode">{{el}}</option>
                    </select>
                </div>

                <div class="flex-1 overflow-auto no-scrollbar">

                    <All model="WorkDays" :immediate="false" v-slot="{data,loading}" :query="{Year:yearselected}" :data.sync="workdays" ref="workdaysreff">

                        <div v-if="!loading" class="h-full  flex flex-col">

                            <div class="h-10 flex flex-row">

                                <div class="h-full w-4/12 flex flex-row justify-start items-center px-2">
                                    <span class="text-sm" :class="'text-dfont-'+mode">{{ $t('month') }}</span>
                                </div>
                                <div class="h-full w-4/12 flex flex-row justify-center items-center">
                                    <span class="text-sm" :class="'text-dfont-'+mode">{{ $t('salesdays') }}</span>
                                </div>
                                <div class="h-full w-4/12 flex flex-row justify-center items-center">
                                    <span class="text-sm" :class="'text-dfont-'+mode">{{ $t('labdays') }}</span>
                                </div>

                            </div>

                            <div class="flex-1 min-h-0 overflow-auto no-scrollbar">

                                <div v-for="(el,index) in data" :key="index" class="h-10 flex flex-row mt-2" :class="{'bg-darkgray_transparent':index%2==0}">

                                    <div class="h-full w-4/12 flex flex-row justify-start items-center px-2">
                                        <span v-if="el.Date" class="text-sm" :class="'text-dfont-'+mode">{{nameMonth(el.Date)}}</span>
                                        <span v-else class="text-sm" :class="'text-dfont-'+mode">-</span>
                                    </div>
                                    <div class="h-full w-4/12 flex flex-row justify-center items-center">
                                        <span class="text-sm" :class="'text-dfont-'+mode">{{el.different_days}}</span>
                                    </div>
                                    <div class="h-full w-4/12 flex flex-row justify-center items-center"  @click="selected = el">
                                        <span class="text-purple text-sm font-semibold">{{el.total_days}}</span>
                                        <!-- <el-input-number v-model="el.total_days" @change="onChange" :min="0" size="mini"></el-input-number> -->
                                    </div>

                                </div>

                            </div>

                        </div>

                        <div v-else class="h-20 relative">
                            <loader :loading="loading"></loader>
                        </div>

                    </All>

                </div>

                    <!-- <Request model="WorkDaysSave" action="create" :form="dates" @success="onSuccess" @error="onError" v-slot="{ request , loading:loadingrequest }" class="w-full">

                        <div v-if="!loadingrequest" class="h-10 bg-red flex flex-col justify-center items-center mt-2 rounded-md" @click="request">

                            <span class="text-white">Guardar</span>

                        </div>

                    </Request> -->
                
            </div>
        </div>

        <div v-if="selected" class="absolute h-full w-full bg-dfont_transparent top-0 left-0 z-30 flex flex-col justify-center items-center">

            <div class="h-56 w-11/12 bg-white rounded-lg flex flex-col justify-center items-center relative">

                <div class="h-10 w-10 rounded-full absolute top-0 right-0 mt-2 mr-2 flex flex-col justify-center items-center" @click="selected = false">
                    <i class="mdi mdi-close text-xl text-dfont"></i>
                </div>
            
                <span v-if="selected.Date" class="text-dfont text-3xl font-semibold">{{nameMonth(selected.Date)}} {{yearselected}}</span>
                <span v-else class="text-dfont text-xl">-</span>

                <span class="text-dfont text-xl mb-2">Días Laborables</span>
                <el-input-number v-model="selected.total_days" @change="onChange" :min="0" size="large" class="text-xl mb-4"></el-input-number>

                <Request model="WorkDaysSave" action="create" :form="{SaleDais:[selected]}" @success="onSuccess" @error="onError" v-slot="{ request , loading:loadingrequest }" class="w-10/12 mx-auto">

                    <div v-if="!loadingrequest" class="h-10 bg-red flex flex-col justify-center items-center mt-2 rounded-md" @click="request">

                        <span class="text-white">Guardar</span>

                    </div>

                </Request>
            
            </div>

        </div>

    </div>
    
</template>

<script>
import { All, Request } from '@/api/components';
import { state, actions } from '@/store';
import loader from '../../components/loader.vue';

export default {
    components:{
        All,
        Request,
        loader
    },
    data(){
        return{
            workdays: [],
            loading: false,
            years:[
                this.$moment().format("YYYY"),
                this.$moment().add(1, 'years').format("YYYY")
            ],
            yearselected:this.$moment().format("YYYY"),
            ifchange:false,
            dates:null,
            firstime:true,
            selected:false
        }
    },
    methods:{
        nameMonth(month){
            return this.$moment(month).format('MMMM').toUpperCase();
        },
        onChange(currentValue, oldValue){

            
            if(this.firstime){
                this.oldvalue = oldValue
            }

            if(this.oldvalue == currentValue){
                this.ifchange = false
            } else {
                this.ifchange = true
            }

            this.firstime = false

        },
        onSuccess(){
            this.selected = false
        },
        onError(){

        }
    },
    computed:{
        mode(){
            return state.mode
        }
    },
    watch:{
        yearselected(){
            this.$refs['workdaysreff'].request();
        },
        workdays(n,o){
            this.dates = n
        }
    },
    mounted(){
        this.$refs['workdaysreff'].request();
    }
}
</script>